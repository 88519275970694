<template>
  <div>
    <search-user @submit="submitSearch" />

    <div class="custom-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="showNewModal">新增</a-button>
      </a-space>
    </div>

    <a-table
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="sortChange"
    >
      <div slot="name" slot-scope="name, record">
        <router-link :to="{ name: 'tenant_halls', params: { id: record.id }}">
          <span>{{ name }}</span>
        </router-link>
      </div>
      <div slot="effective" slot-scope="effective, record">
        <a-switch
          :checked="effective"
          :loading="editingEffectiveId === record.id"
          :disabled="!isCanUpdateEffective(record.id, record.role_slug)"
          @change="(checked) => changeEffective(checked, record)"
        />
      </div>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="setTouchScreenPictures(record)" v-if="isCanEdit(record.id, record.role_slug)">触摸屏屏保</a>
          <a @click="showEditModal(record)" v-if="isCanEdit(record.id, record.role_slug)">编辑</a>
          <a @click="deleteTenantAdmin(record.id)" class="custom-delete">删除</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增用户模态框 -->
    <new-tenant-admin
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <!-- 编辑用户模态框 -->
    <edit-tenant-admin
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :record="editingRecord"
      @completed="fetchData"
    />

    <set-touch-screen-picture
        :id="setTouchScreenTenantId"
        :visible.sync="isShowSetTouchScreenPicsModal"
        v-if="isShowSetTouchScreenPicsModal"
        @completed="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { findTenantAdmin, updateTenantAdminEffective, deleteTenantAdmin } from '@/api/tenant'
import SearchUser from '@/views/tenants/admin/Search'
import { hasPermission } from '@/utils/permission'
import SetTouchScreenPicture from "@/views/tenants/admin/SetTouchScreenPicture.vue";

export default {
  name: 'TenantAdminList',
  components: {
    SetTouchScreenPicture,
    Pagination,
    SearchUser,
    NewTenantAdmin: () => import('@/views/tenants/admin/New'),
    EditTenantAdmin: () => import('@/views/tenants/admin/Edit')
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      editingEffectiveId: 0, // 正在修改有效性的记录id
      editingRecord: {}, // 正在编辑的记录id
      pagination: {
        total_count: 0
      },
      sort: {
        sort_field: '',
        sort_order: ''
      },
      isShowSetTouchScreenPicsModal: false,
      setTouchScreenTenantId: 0,
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '租户名称',
          dataIndex: 'name',
          width: 120,
          fixed: 'left',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '订单数量',
          width: 120,
          dataIndex: 'order_count',
          sorter: true,
          sortOrder: this.sort.sort_field === 'order_count' ? this.sort.sort_order : false
        },
        {
          title: '厅房数量',
          width: 120,
          dataIndex: 'hall_count',
          sorter: true,
          sortOrder: this.sort.sort_field === 'hall_count' ? this.sort.sort_order : false

        },
        {
          title: '员工数量',
          width: 120,
          dataIndex: 'staff_count',
          sorter: true,
          sortOrder: this.sort.sort_field === 'staff_count' ? this.sort.sort_order : false
        },
        {
          title: '套餐数量',
          width: 120,
          dataIndex: 'package_count',
          sorter: true,
          sortOrder: this.sort.sort_field === 'package_count' ? this.sort.sort_order : false
        },
        {
          title: '产品数量',
          width: 120,
          dataIndex: 'product_count',
          sorter: true,
          sortOrder: this.sort.sort_field === 'product_count' ? this.sort.sort_order : false
        },
        {
          title: '登陆账号',
          width: 100,
          dataIndex: 'emp_no'
        },
        {
          title: '联系人',
          dataIndex: 'contact',
          width: 100
        },
        {
          title: '联系电话',
          width: 160,
          dataIndex: 'phone_number'
        },
        {
          title: '状态',
          width: 100,
          dataIndex: 'effective',
          scopedSlots: { customRender: 'effective' }
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          width: 120,
          sorter: true,
          sortOrder: this.sort.sort_field === 'created_at' ? this.sort.sort_order : false
        },
        {
          title: '操作',
          width: 200,
          fixed: 'right',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    },

    isHasEditPermission() {
      return true
    }
  },
  methods: {
    setTouchScreenPictures(record) {
      // 设置屏保图片
      this.setTouchScreenTenantId = record.id
      this.isShowSetTouchScreenPicsModal = true
    },
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    // 是否可以编辑某条记录
    isCanEdit(recordId, recordRole) {
      if (!this.isHasEditPermission) {
        return false
      }
      return true
    },

    // 是否可以编辑某条记录有效性
    isCanUpdateEffective(recordId, recordRole) {
      if (!this.isHasEditPermission) {
        return false
      }
      return true
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(record) {
      this.editingRecord = record
      this.isShowEditModal = true
    },
    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findTenantAdmin(Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    changeEffective(checked, record) {
      const vm = this
      const effective = checked

      this.$confirm({
        title: effective ? '确定将账号生效吗?' : '确定进行失效吗？失效后，此租户系统下所有账号均无法登录后台管理系统。\n' +
            '\n',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          vm.editingEffectiveId = record.id
          updateTenantAdminEffective(record.id, { effective: effective })
            .then((res) => {
              vm.editingEffectiveId = 0
              if (res.code === 0) {
                record.effective = checked
              }
            })
            .catch(() => {
              vm.editingEffectiveId = 0
            })
        }
      })
    },

    deleteTenantAdmin(recordId) {
      const thisForm = this
      this.$confirm({
        title: '确定进行删除吗？',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          deleteTenantAdmin(recordId).then(() => {
            thisForm.fetchData()
          })
        }
      })
    }
  }
}
</script>
