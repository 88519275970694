<template>
  <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="设置触摸屏屏保"
      @ok="handleSubmit"
  >
    <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
    >
      <a-form-item label="屏保">
          <span
              class="tip-text"
          >支持png/jpg格式照片，10M以内，最多1张
          </span>
        <upload-image
            :is-add-watermark="isAddWatermark"
            accept=".jpg,.jpeg,.png"
            :max-count="1"
            :multiple="true"
            @change="handleUploadImage"
            :default-file-list="defaultFileList"
            v-decorator="['attachments',{
              rules:[
                {validator: checkAttachments}
              ]
            }]"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import {findTenant, setTouchScreenPics} from "@/api/tenant";
import UploadImage from "@/components/Upload/Image.vue";

export default {
  name: "SetTouchScreenPicture",
  components: {UploadImage},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'tenantAdminPicture' }),
      submitting: false,
      isAddWatermark: false,
      attachments: [], // 图片
      defaultFileList: []
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchTenantInfo()
  },
  methods: {
    handleUploadImage(fileList) {
      this.attachments = fileList
    },
    // 检查照片
    checkAttachments(rule, value, callback) {
      for (const key in this.attachments) {
        if (this.attachments[key] && this.attachments[key].status === 'error') {
          // eslint-disable-next-line standard/no-callback-literal
          callback('照片上传错误')
          return
        }
      }

      callback()
    },
    fetchTenantInfo() {
      this.defaultFileList = []
      findTenant(this.id).then(res => {
        if (res.code === 0) {
          res.data.touch_screen_pics.forEach((item, index) => {
            this.defaultFileList.push({
              uid: index,
              name: 'image.png',
              status: 'done',
              url: item.url,
              response: item.response
            })
          })
          this.attachments = this.defaultFileList
        }
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          const data = {
            ...values,
            attachments: this.$lodash.map(this.attachments, 'response')
          }
          setTouchScreenPics(this.id,data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
